import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { getAllBanks, getBankDetails, playerWithdrawal, verifyBankAccount } from "../../Services/apis";
import {
  SET_ACTION_PROP,
  SET_LOADING_PROP,
  SET_TOAST_PROPS,
  UPDATE_USER_BALANCE,
} from "../../Redux/types";
import { useDispatch, useSelector } from "react-redux";
import { goBack } from "../../Utils/helpers";
import { toast } from "react-toastify";
import "../../Assets/scss/_deposit.scss";

const Withdrawal = ({ history }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const type = queryParams.get('type');

  const [formData, setFormData] = useState({
    amount: 0,
    bank_code: "",
    account_number: '',
    account_name: '',
    recipient_code: '',
    type
  });

  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [bankData, setBankData] = useState({});
  const [showErr, setShowErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [withdrawalData, setWithdrawalData] = useState({});
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticated) history.replace("/");
  }, [isAuthenticated]);

  useEffect(() => {
    fetchBanks();
    fetchBankData();
  }, []);

  useEffect(() => {
    if (bankData) {
      setFormData({
        ...formData,
        account_number: bankData.account_number,
        account_name: bankData.account_name,
        bank_code: bankData.bank_code
      })
    }
  }, [bankData]);

  const fetchBanks = (page) => {
    getAllBanks()
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchBankData = () => {
    getBankDetails()
      .then((res) => {
        if (res.success) {
          setBankData(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const updateAmount = (value) => {
    if (value === 0) {
      setFormData({ ...formData, amount: 0 })
      return;
    }

    let currentAmount = formData.amount;
    if (currentAmount === "") {
      currentAmount = 0;
    }
    const newAmount = currentAmount + value;
    setFormData({ ...formData, amount: newAmount })
  };

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch({ type: SET_LOADING_PROP, payload: { show: true, message: "" } });
    playerWithdrawal(formData)
      .then((res) => {
        setLoading(false);
        dispatch({
          type: SET_LOADING_PROP,
          payload: { show: false, message: "" },
        });

        if (res.success) {
          setFormData({});
          setShowErr(false);
          dispatch({ type: UPDATE_USER_BALANCE, payload: res.balance });
          if (type === 'online') {
            toast.success(res.message);
          } else {
            setShow(true);
            setWithdrawalData(res?.data);
          }
        } else {
          setShow(false);

          setShowErr(true);
          setErrMsg(res?.message);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setShowErr(false);
        setShow(false);
        toast.error(err.message);
        setLoading(false);
        dispatch({
          type: SET_LOADING_PROP,
          payload: { show: false, message: "" },
        });
        dispatch({
          type: SET_ACTION_PROP,
          payload: {
            show: true,
            title: "Error",
            message: "Unable to process request. Please try again",
          },
        });
      });
  };

  const doVerify = async (account_number) => {
    setLoading(true);
    await verifyBankAccount({
      account_number,
      bank_code: formData.bank_code,
    })
      .then(res => {
        setLoading(false);
        if (res.success) {
          setVerified(true);
          setFormData({
            ...formData,
            account_name: res.data.account_name,
            recipient_code: res.data.recipient_code,
            account_number
          })
        } else {
          setVerified(false);
          setFormData({
            ...formData,
            account_name: '',
            recipient_code: ''
          })
          toast.error(res.message);
        }
      })
      .catch(err => {
        setLoading(false);
        toast.error(err.response.data.message);
      })
  };

  return (
    <Layout
      headerLeft={
        <div
          className="h-s__wrap-trigger px15 py10"
          onClick={() => goBack(history)}
        >
          <i className="icon-back" />
          <span className="d-ib ml5">Back</span>
        </div>
      }
    >
      <div className="page-title"> Withdraw Funds</div>
      {show && (
        <p className="code-card" style={{ background: "green" }}>
          {" "}
          Your Withdrawal Pin is:{" "}
          <strong>{withdrawalData?.requests?.withdraw_code}</strong>
          <br />
          Take to the shop for payment.
        </p>
      )}
      {showErr && (
        <p className="code-card" style={{ background: "red" }}>
          {" "}
          {errMsg}
        </p>
      )}
      <div className="deposit">

        <div className="page__body p15">
          <div className="form">
            <div className="clear-both" />
            <div className="">
              <div className="form-row">
                <div className="form-label">
                  <strong>
                    {" "}
                    Withdrawal Amount ({SportsbookGlobalVariable.Currency})
                  </strong>
                </div>
                <div className="form-input">
                  <input
                    name="amount"
                    className="big"
                    type="number"
                    step="100"
                    maxLength={5}
                    min="100"
                    max="10000"
                    value={formData.amount}
                    onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                  />
                  <div className="form-input--stake"> Min {SportsbookGlobalVariable.MinWithdrawal}</div>
                </div>
                <div className="quickstake mt10">
                  <div
                    className="quickstake__item"
                    onClick={() => updateAmount(0)}
                  >
                    {" "}
                    Clear
                  </div>
                  <div
                    className="quickstake__item"
                    onClick={() => updateAmount(100)}
                  >
                    {" "}
                    +100
                  </div>
                  <div
                    className="quickstake__item"
                    onClick={() => updateAmount(200)}
                  >
                    {" "}
                    +200
                  </div>
                  <div
                    className="quickstake__item"
                    onClick={() => updateAmount(500)}
                  >
                    {" "}
                    +500
                  </div>
                  <div
                    className="quickstake__item"
                    onClick={() => updateAmount(1000)}
                  >
                    {" "}
                    +1000
                  </div>
                </div>
              </div>
              {SportsbookGlobalVariable.EnableBankAcct == 1 && type === 'online' &&
                <div className="deposit-step">
                  {bankData === null || {} ? (
                    " "
                  ) : (
                    <div className="bank">
                      <div className="bank-details">
                        <p className="ml-4">Account Name:</p>
                        <p className="">
                          <strong>{bankData?.account_name}</strong>
                        </p>
                      </div>
                      <div className="bank-details">
                        <p className="ml-4">Account Number:</p>
                        <p className="">
                          <strong>{bankData?.account_number}</strong>
                        </p>
                      </div>
                      <div className="bank-details">
                        <p className="ml-4">Bank Name:</p>
                        <p className="">
                          <strong>{bankData?.bank_name}</strong>
                        </p>
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="form-box my-1">
                      <label className="w-2">Bank</label>
                      <select
                        name="bank_code"
                        type="text"
                        value={formData.bank_code}
                        onChange={(e) => setFormData({ ...formData, bank_code: e.target.value })}
                      >
                        <option value="Card">Select a bank..</option>
                        {data &&
                          data?.map((item, i) => (
                            <option value={item.code} key={i}>
                              {item?.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-box my-1">
                      <label className="w-2">Account No.</label>
                      <input
                        name="account"
                        value={formData.account_number}
                        onChange={(e) => {
                          setFormData({ ...formData, account_number: e.target.value });
                          if (e.target.value.length === 10) {
                            doVerify(e.target.value);
                          }
                        }}
                        type="number"
                        className="deposit-input"
                      />
                    </div>
                    <div className="form-box my-1">
                      <label className="w-3">Account Name</label>
                      <input
                        name="account_name"
                        value={formData.account_name}
                        type="text"
                        className="deposit-input"
                      />
                    </div>
                    {verified && <div className="bank-details">
                      <p className="ml-4">Account Name:</p>
                      <p className="">
                        <strong>{formData.account_name}</strong>
                      </p>
                    </div>}
                  </div>
                </div>}
              <div className="btn-bank">
                <button disabled={loading || formData.amount === 0} onClick={submit}>Withdraw</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Withdrawal;
