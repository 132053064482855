export const SET_SPORTS     = 'SET_SPORTS';
export const SET_SPORT     = 'SET_SPORT';
export const SET_ACTIVE_PERIOD   = 'SET_ACTIVE_PERIOD';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_TOURNAMENTS = 'SET_TOURNAMENT';
export const SET_FIXTURES = 'SET_FIXTURES';
export const SET_FIXTURE = 'SET_FIXTURE';

export const SET_USER_DATA = '[USER] SET DATA';
export const SET_SIGNUP_DATA = '[AUTH] SET SIGNUP DATA';
export const UPDATE_USER_DATA = '[USER] UPDATE USER DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';
export const UPDATE_USER_BALANCE = '[USER] UPDATE BALANCE';
export const UPDATE_USERNAME = '[USER] UPDATE USERNAME';
export const UPDATE_VIRTUAL_LINK = '[USER] UPDATE VIRTUAL LINK';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOADING = 'LOADING';

export const SET_COUPON_DATA = '[COUPON] SET DATA';
export const SET_LOADED_DATA = '[COUPON] LOAD DATA';
export const RESET_COUPON_AMOUNT = '[COUPON] RESET AMOUNT';
export const SET_BET_PLACED = '[COUPON] SET BET PLACED';
export const SET_USE_BONUS = '[COUPON] SET USE BONUS';
export const CANCEL_BET = '[COUPON] CANCEL BET';
export const SET_TIPSTER_BETSLIPID = '[COUPON] SET TIPSTER BETSLIP ID';

export const SET_BONUS_LIST = 'SET BONUS LIST';
export const SET_GLOBAL_VAR = 'SET GLOBAL VARIABLES';
export const SET_LOADING_PROP = 'SET LOADING PROPS';
export const SET_ACTION_PROP = 'SET ACTION PROPS';
export const SET_TOAST_PROPS = 'SET TOAST PROPS';
export const SET_SHOW_DOWNLOAD = 'SET SHOW DOWNLOAD';
export const SET_LIVE_VIEW = 'SET LIVE VIEW';

export const SHOW_MODAL = 'SHOW MODAL';
export const CLOSE_MODAL = 'CLOSE MODAL';
