import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useSelector } from "react-redux";
import { MD5 } from "crypto-js";

export default function Virtual() {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const [mode, setMode] = useState(0);
  const [token, setToken] = useState("111111");
  const [hash, setHash] = useState(null);
  const [group, setGroup] = useState(process.env.REACT_APP_SITE_KEY);
  const backurl =  process.env.REACT_APP_SITE_URL;
  const privateKey = process.env.REACT_APP_XPRESS_PRIVATE_KEY;

  // const goTo = (path) => {
  //   history.push(path);
  // };


  useEffect(() => {
    if (isAuthenticated) {
      setMode(1);
      setGroup(user?.group);
      setToken(user?.auth_code);
      
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    setHash(
      MD5(
        `${token}10100${backurl}${mode}${group}mobile${privateKey}`
      ).toString()
    );
  }, [token]);

  return (
    <Layout>
      {/* <div id="globalbet" /> */}
      {hash &&
        <iframe
          title="virtual"
          style={{
            width: "100%",
            border: 0,
            height: "100vh",
            overflow: "scroll",
          }}
          // style={{ width: '100%', border: 0, height: '100vh', overflow: scroll !important}}
          src={`${process.env.REACT_APP_XPRESS_LAUNCH_URL}?token=${token}&game=10100&backurl=${backurl}&mode=${mode}&group=${group}&clientPlatform=mobile&h=${hash}`}
        />
      }
    </Layout>
  );
}

