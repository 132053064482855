import React, { useCallback, useEffect, useState } from "react";

// import React, { useEffect } from "react";
import Layout from "./Layout";
import { goBack } from "../Utils/helpers";
import Loader from "./Components/Loader";
import { verifyTransaction } from "../Services/apis";
import { toast } from "react-toastify";
import { useParams } from "react-router";

export default function PaymentVerification({ history }) {
  const queryParams = new URLSearchParams(window.location.search)
  const [trxRef, setTrxRef] = useState(null); 
  const {type: gateway} = useParams();

  useEffect(() => {
    switch (gateway) {
      case 'monnify':
        setTrxRef(queryParams.get('paymentReference'))
        break;
      case 'flutterwave':
        const status = queryParams.get('status');
        if (status === 'cancelled') {
          toast.error('Transaction cancelled');
          setTimeout(() => {
              window.location.href = '/account/deposit';
            }, [3000]);
        } else {
          setTrxRef(queryParams.get('tx_ref'));
        }
        setTrxRef(queryParams.get('tx_ref'))
        break;
      case 'paystack':
        setTrxRef(queryParams.get('trxref'))
        break;
      default:
        break;
    }
  }, [gateway]);


  useEffect(() => {
    async function verify(data) {
      await verifyTransaction(data).then(res => {
        if(res.success) {
          toast.success('Payment succesful!')
          setTimeout(() => {
            window.location.href = '/';
          }, [3000]);
        } else {
          toast.error(res.message);
          // setTimeout(() => {
          //   window.location.href = '/account/deposit';
          // }, [3000]);
        }
      }).catch(err => toast.error('We are unable to verify payment'));
    }

    if(trxRef) verify({paymentChannel: gateway, trxRef})

  }, [trxRef]);

  return (
    <Layout
      footer={false}
      headerLeft={
        <div
          className="h-s__wrap-trigger px15 py10"
          onClick={() => goBack(history)}
        >
          <i className="icon-back" />
          <span className="d-ib ml5">Back</span>
        </div>
      }
    >
      <Loader loading={true} />
      <div style={{textAlign: 'center'}}>
        <h3>Confirming Payment</h3>
        <h3>please wait...</h3>
      </div>
    </Layout>
  );
}

