import React, { useRef, useState, useEffect } from "react";
import Layout from "../Layout";
import { goBack } from "../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { confirmVerification, login, register, sendOtp, sendVerification, verifyCode } from "../../Services/apis";
import { toast } from "react-toastify";
import { SET_USER_DATA } from "../../Redux/types";

export default function Verify({ history }) {
  const [sending, setSending] = useState(false);
  const [otpStatus, setOtpStatus] = useState({ loading: false, status: "" });
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const { user, signup } = useSelector((state) => state.auth);

  const [otpRef, setOtpRef] = useState({
    otp1: useRef(),
    otp2: useRef(),
    otp3: useRef(),
    otp4: useRef(),
    otp5: useRef(),
    otp6: useRef(),
  });

  useEffect(() => {
    if (user?.verified === 1) {
      history.replace("/");
    }
  }, [user]);

  const sendSMS = async () => {
    setSending(true);
    await sendVerification({ username: signup.phone })
      .then((res) => {
        setSending(false);
        toast.success("Please check your phone for your verification code");
      })
      .catch((err) => {
        setSending(false);
        toast.error("Unable to send SMS. Please try again");
      });
  };

  const confirmOtp = async (otp) => {
    if (otp) {
      try {
        setOtpStatus({ ...otpStatus, loading: true });
        await confirmVerification({ otp, username: signup.phone })
          .then((res) => {
            if (res.success) {
              register(signup)
                .then((res) => {
                  setOtpStatus({ ...otpStatus, loading: false });
                  if (res.success) {
                    const { username, password } = res.credentials;
                    login({ username, password })
                      .then((res) => {
                        dispatch({
                          type: SET_USER_DATA,
                          payload: {
                            user: res.user,
                            access_token: res.token,
                            isAuthenticated: true,
                          },
                        });
                        history.push("/");
                      })
                      .catch((err) => {
                        console.log(err?.response);
                        if (err?.response?.status === 401) {
                          toast.error(err?.message);
                        }
                      });
                  }
                })
                .catch((err) => {
                  setOtpStatus({ ...otpStatus, loading: false });
                  if (err?.response?.status === 422) {
                    let errors = Object.values(err?.response?.data?.errors);
                    errors = errors?.flat();
                    toast.error(errors);
                  }
                  toast.error(err?.response?.data?.message);
                });
            } else {
              setOtpStatus({ ...otpStatus, status: false });
              toast.error(res.message);
            }
          })
          .catch((err) => {
            setOtpStatus({ ...otpStatus, loading: false });
            toast.error("Invalid verification code.");
          });
      } catch (e) {
        history.goBack();
        toast.error("Oops!! Something went wrong");
      }
    } else {
      toast.error("Please enter a valid code");
    }
  };

  const otpController = (e, next, prev, index) => {
    if (e.target.value.length < 1 && prev) {
      const code = otp.slice(0, -1);
      if (index === 1) {
        setOtp(code);
      } else {
        setOtp("");
      }
      prev.current.focus();
    } else if (next && e.target.value.length > 0) {
      const code = otp + e.target.value;
      // add value to code
      setOtp(code);
      next.current.focus();
    } else {
      const code = otp + e.target.value;
      // add value to code
      setOtp(code);
      if (index === 5) {
        confirmOtp(parseInt(code));
      }
      return 0;
    }
  };

  return (
    <Layout
      bottom={false}
      headerLeft={
        <div
          className="h-s__wrap-trigger px15 py10"
          onClick={() => goBack(history)}
        >
          <i className="icon-back" />
          <span className="d-ib ml5">Back</span>
        </div>
      }
      headerRight="&nbsp;"
    >
      <div className="login-form">
        <div className="login-form__wrap">
          <div className="login-form__heading" style={{ marginBottom: "50px" }}>
            <p>Enter the code sent to your phone number </p>
          </div>

          <div className="aqx-loax-c">
            <div className="dnxreg-box">
              <div className="dnxreg-box-a">
                <label htmlFor="" className="nxlabel">
                  Code from SMS*
                </label>
              </div>
              <div className="dnxreg-box-b smsx-code">
                <input
                  type="text"
                  ref={otpRef.otp1}
                  className="nxfield"
                  onChange={(e) => otpController(e, otpRef.otp2, "", 0)}
                  placeholder="-"
                  maxLength={1}
                />
                <input
                  type="text"
                  ref={otpRef.otp2}
                  className="nxfield"
                  onChange={(e) =>
                    otpController(e, otpRef.otp3, otpRef.otp1, 1)
                  }
                  placeholder="-"
                  maxLength={1}
                />
                <input
                  type="text"
                  ref={otpRef.otp3}
                  className="nxfield"
                  onChange={(e) =>
                    otpController(e, otpRef.otp4, otpRef.otp2, 2)
                  }
                  placeholder="-"
                  maxLength={1}
                />
                <input
                  type="text"
                  ref={otpRef.otp4}
                  className="nxfield"
                  onChange={(e) =>
                    otpController(e, otpRef.otp5, otpRef.otp3, 3)
                  }
                  placeholder="-"
                  maxLength={1}
                />
                <input
                  type="text"
                  ref={otpRef.otp5}
                  className="nxfield"
                  onChange={(e) =>
                    otpController(e, otpRef.otp6, otpRef.otp4, 4)
                  }
                  placeholder="-"
                  maxLength={1}
                />
                <input
                  type="text"
                  ref={otpRef.otp6}
                  className="nxfield"
                  onChange={(e) => otpController(e, "", otpRef.otp5, 5)}
                  placeholder="-"
                  maxLength={1}
                />
                <div style={{ margin: "auto" }}>
                  {otpStatus.loading ? (
                    <i
                      className="fa fa-spin fa-spinner"
                      style={{ color: "white" }}
                    />
                  ) : (
                    {
                      true: (
                        <i className="fa fa-check" style={{ color: "green" }} />
                      ),
                      false: (
                        <i className="fa fa-times" style={{ color: "red" }} />
                      ),
                    }[otpStatus.status]
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="aqx-loax-b" style={{ marginBottom: "30px" }}>
            <button
              type="button"
              className="aqx-loax-btn"
              onClick={() => confirmOtp(parseInt(otp))}
              disabled={otpStatus.loading}
            >
              Submit {otpStatus.loading && <i className="fa fa-spin fa-spinner" />}
            </button>
          </div>
          <div style={{ textAlign: 'center' }} onClick={sendSMS}>
            <p style={{ marginTop: '20px', textDecoration: 'underline' }}>Re-send Code</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
